<template>
  <MasterDetail
    formTitle="Item Nota Fiscal"
    :cols="cols"
    :resourceUrl="resourceUrl"
    descriptionProperty="descr_compl"
    :canDelete="false"
    :formWidth="900"
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";

export default {
  components: {
    MasterDetail,
  },
  computed: {
    nfId: function () {
      return this.$route.params.nfId;
    },
    resourceUrl: function () {
      return `/v1/faturamento/nfe/${this.getClient().clientId}/${this.nfId}/itens`;
    },
  },
  created: function () {
  },
  data: function () {
    return {
      cols: [
        {
          key: "nfId",
          name: "NF ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "cfop",
          name: "CFOP",
          type: this.$fieldTypes.TEXT,
          colSize:2
        },
        {
          key: "desc_cfop",
          name: "Descrição CFOP",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
          colSize:10
        },
                {
          key: "num_item",
          name: "Nº Item",
          type: this.$fieldTypes.TEXT,
          colSize:2
        },
        {
          key: "ncm",
          name: "NCM",
          type: this.$fieldTypes.TEXT,
          colSize:2
        },
        {
          key: "codigo",
          name: "COD",
          type: this.$fieldTypes.TEXT,
          colSize:2
        },
        {
          key: "descr_compl",
          name: "Descrição",
          type: this.$fieldTypes.TEXT,
          colSize:6
        },
        {
          key: "unidade",
          name: "UN",
          type: this.$fieldTypes.TEXT,
          colSize:3
        },
        {
          key: "quantidade",
          name: "Qtde",
          type: this.$fieldTypes.TEXT,
          colSize:3
        },
        {
          key: "vl_item",
          name: "Vlr Item",
          type: this.$fieldTypes.MONEY, nowrap: true,
          colSize:3
        },
        {
          key: "vlr_oper",
          name: "Vlr Operação",
          type: this.$fieldTypes.MONEY, nowrap: true,
          colSize:3
        },
        {
          key: "produto",
          name: "Produto",
          type: this.$fieldTypes.TEXT,
          colSize:6
        },
        {
          key: "modelo",
          name: "Modelo",
          type: this.$fieldTypes.TEXT,
          colSize:6
        },
       
        {
          key: "comp_ped",
          name: "comp_ped",
          type: this.$fieldTypes.TEXT,
          colSize:4
        },
        {
          key: "situacao_nf",
          name: "Situação",
          type: this.$fieldTypes.TEXT,
          colSize:4
        },
        {
          key: "calculo",
          name: "Cálculo",
          type: this.$fieldTypes.TEXT,
          colSize:4
        },
         {
          key: "inf_adic_prod",
          name: "Informações adicionais",
          type: this.$fieldTypes.TEXTAREA,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
    };
  },
};
</script>
